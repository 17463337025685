<template>
  <a-layout class="home-container">
    <!-- 头部区域 -->
    <vb-header></vb-header>
    <!-- 页面主体区域 -->

      <!-- 页面内容主体 -->
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>

  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import vbHeader from '@/views/header'
export default {
  name: 'mainBox',
  data () {
    return {

    }
  },
  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  components: {
    vbHeader
  },
  computed: {
    ...mapState(['userList'])
  }
}
</script>
<style scoped>
  .ant-layout{background: #fff;}
  .ant-layout-content{width: 1200px; margin: 0 auto;padding-top: 33px;}
</style>
