<template>
<div class="header">
  <div class="header_content">
      <div>
        <img src="~/assets/images/logo.png" />
        <span>VB-MAPP评估系统</span>
      </div>
      <a-button @click="logout">退出</a-button>
  </div>
  </div>

</template>
<script>
export default {
  name: 'vbHeader',
  data () {
    return {
    }
  },
  methods: {
    logout () {
      // window.sessionStorage.removeItem('token')
      window.sessionStorage.clear()
      this.$router.replace('/login')
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  height: 90px;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 0 5px 0.5px rgb(0 0 0 / 20%);

  .header_content{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    img {
      width: 120px;
      height: 46px;

    }

  }
  .ant-btn{border-color:var(--color-tint)}
  .header_content span{width:205px; height: 34px;font-size: 20px;  text-align: center; border: 1px solid #333;border-radius: 30px; display: inline-block;color: #333;margin-left: 14px;    vertical-align: middle;}
}

</style>
